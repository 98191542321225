<template>
  <div>
    <h1>アラート未申請一覧</h1>
    <v-card outlined class="mb-5">
      <v-expansion-panels flat v-model="panel">
        <v-expansion-panel>
          <!-- Header -->
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- <v-row>
              <v-col colspan="12">
                <DatePicker
                  class="mt-10"
                  v-model="date"
                  label="対象年月"
                  type="month"
                  outlined
                />
              </v-col>
            </v-row> -->
            <!-- <v-row>
              <v-col colspan="12">
                <v-autocomplete
                  v-model="selectFilterAffiliations"
                  :items="filterAffiliations"
                  label="所属"
                  clearable
                  chips
                  outlined
                  multiple
                  item-text="name"
                  item-value="code"
                >
                </v-autocomplete>
              </v-col>
            </v-row> -->
            <v-row>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="LostStamp"
                  label="打刻漏れ"
                  checked
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="LateStamp"
                  label="遅刻"
                  checked
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="EarlyStamp"
                  label="早退"
                  checked
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="Overwork"
                  label="残業"
                  checked
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="HolyWork"
                  label="公休出勤"
                  checked
                ></v-checkbox>
              </v-col>
              <!-- <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="NotStamp"
                  label="未打刻"
                  checked
                ></v-checkbox>
              </v-col> -->
            </v-row>
            <v-row>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  v-model="NotStamp"
                  label="打刻漏れに本日を含む"
                  checked
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="getselect()">取得</Btn>
      </v-card-actions>
    </v-card>
    <!-- Table -->
    <v-card class="mt-5">
      <v-card-title>
        <span class="text-h4">{{ title }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="簡易検索"
          outlined
          rounded
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :search="search"
        multi-sort
      >
        <!-- <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :readonly="item.disabled"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template> -->
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
const DATE = new Date();

const SelectsConfirm = {
  No: "0",
  Yes: "1"
};
export default {
  name: "AlertDetailsList",
  mixins: [Common, ShowDialogs, Api],
  props: {},
  data() {
    return {
      date: `${DATE.getFullYear()}-${DATE.getMonth() + 1}-${DATE.getDate()}`,
      selected: [],
      search: "",
      open: true,
      title: "テーブル名",
      itemKey: "name",
      headers: [],
      items: [],
      disabledCount: 0,
      displayDate: "",
      SelectsConfirm,
      selectFilterAffiliations: [],
      filterAffiliations: [],
      filterCalc: SelectsConfirm.No,
      panel: null
    };
  },
  computed: {
    isSelected() {
      return !!this.selected && this.selected.length > 0;
    }
  },
  methods: {
    selectAllToggle(props) {
      console.log("selectAllToggle", props);
      if (!props.value) {
        this.selected = [];
        return;
      }
      if (this.selected.length != this.items.length - this.disabledCount) {
        this.selected = [];
        props.items.forEach(item => {
          this.selected.push(item);
        });
      } else this.selected = [];
    },
    async getdata() {
      console.log("getdata");
      try {
        this.filterAffiliations = await this.$get(
          this.Paths.shozokuFilterForCalc
        );
        const data = await this.$get(this.Paths.alert.list);
        await this.setdata(data);
        // this.displayDate = await this.getPaymentDate();
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getselect() {
      // const paymentDate = await this.getPaymentDate();
      // const authority = this.$store.state.authority;

      // // 現場リーダーの場合は、所属未選択は不可とする
      // if (authority === "1" && this.selectFilterAffiliations.length === 0) {
      //   this.$error("所属を1つ以上選択してください。");
      //   return;
      // }

      this.selectFilterAffiliations = [];
      this.filterAffiliations.forEach(record => {
        this.selectFilterAffiliations.push(record.code);
      });

      console.log(
        "this.selectFilterAffiliations",
        this.selectFilterAffiliations
      );

      const param = {
        affiliationId: this.selectFilterAffiliations,
        lostStamp: this.LostStamp == true ? 1 : 0,
        lateStamp: this.LateStamp == true ? 1 : 0,
        earlyStamp: this.EarlyStamp == true ? 1 : 0,
        overwork: this.Overwork == true ? 1 : 0,
        holyWork: this.HolyWork == true ? 1 : 0,
        notStamp: this.NotStamp == true ? 1 : 0
      };

      await this.getSelectWithParam(param);
    },
    async getSelectWithParam(param) {
      console.log("getselect");
      this.$loading();
      try {
        this.selected = [];
        this.disabledCount = 0;
        const encode = encodeURI(JSON.stringify(param));
        console.log("encode", encode);
        const data = await this.$get(this.Paths.alert.list, "query=" + encode);
        await this.setdata(data);
        this.displayDate = param.paymentDate;
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async setdata(data) {
      console.log("setdata");
      this.headers = data.headers;
      this.headers.push({ text: "actions", value: "actions" });
      this.items = data.items;
      this.title = data.name;
      this.itemKey = data.key;
      this.items.map(item => {
        if (item.disabled) this.disabledCount += 1;
      });

      this.displayDate = "";
    },

    async getTargetDateString(targetDate) {
      return (
        targetDate.getFullYear() +
        "-" +
        targetDate.getMonth() +
        "-" +
        targetDate.getDate()
      );
    },
    async getPaymentDate() {
      const ym = this.date.split("-");
      const dt = new Date(Number(ym[0]), Number(ym[1]), 1);
      dt.setDate(dt.getDate() - 1);

      const result =
        dt.getFullYear() +
        "-" +
        ("00" + Number(dt.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + dt.getDate()).slice(-2);

      return result;
    }
  },
  async created() {
    console.log("==created");
    this.date = this.$getAiwaServiceDate();
    console.log(this.date);
    await this.getdata();
    console.log("query", this.$route.query);
    this.LostStamp = true;
    this.LateStamp = true;
    this.EarlyStamp = true;
    this.Overwork = true;
    this.HolyWork = true;
    this.NotStamp = false;
    console.log("Lost", this.LostStamp);
    await this.getselect();
    // if ("filter" in this.$route.query) {
    //   const param = this.$route.query.filter;
    //   /*if (typeof param !== "undefined" && Object.keys(param).length) {
    //     this.selectFilterAffiliations = param.affiliationIds;
    //     this.filterCalc = param.calcFlag;
    //     this.date = param.paymentDate;
    //     await this.getSelectWithParam(param);
    //   }*/
    // }
  },
  mounted() {
    console.log("==mounted");
  },
  beforeUpdate() {
    console.log("==beforeUpdate");
  },
  updated() {
    console.log("==updated");
  }
};
</script>
